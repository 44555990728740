<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :headers="headers"
      :action="getUploadUrl"
      :data="formData"
      :show-file-list="false"
      :on-success="handleSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :on-progress="handleUploadProgress"
      v-loading="isLoading"
    >
      <div
        v-if="fileUrl && !validURL(fileUrl) && fileUrl.length"
        class="avatar"
      >
        <el-image :src="fileUrl"></el-image>
      </div>
      <div v-else>
        <img
          v-if="fileUrl"
          :src="fileUrl"
          class="avatar"
          style="object-fit: contain"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </div>
      <slot name="tip"></slot>
    </el-upload>
  </div>
</template>

<script>
import BaseUploader from "./../BaseUploader";
export default {
  mixins: [BaseUploader],
  methods: {
    beforeAvatarUpload(file) {
      // // have to perform file validation here. Disabling it now since we dont have any other type of uploaders
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error(__("Image must be JPG format!"));
      }
      if (!isLt2M) {
        this.$message.error(__("Image size can not exceed 2MB!"));
      }
      return isJPG && isLt2M;
    },
    validURL(str) {
      let pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
::v-deep .el-image__inner {
  vertical-align: center !important;
}
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed $--border-color-light;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload:hover {
  border-color: var(--theme-color);
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $--color-text-secondary;
  min-width: 125px;
  min-height: 125px;
  max-width: 125px;
  max-height: 125px;
  line-height: 125px;
  text-align: center;
}
.avatar {
  min-width: 125px;
  min-height: 125px;
  max-width: 150px;
  max-height: 150px;
  display: block;
}
</style>
